import React from "react";
import { Box, FlexBox } from "../../Box";
import { Logo } from "../../logo/Logo";
import { NavListElements } from "./NavListElements";
import { ContentContainer } from "./DesktopNavigation.styled";

export const DesktopNavigation = () => {
  return (
    <ContentContainer>
      <Box height="72px">
        <FlexBox maxWidth="1140px" margin="0 auto" height="100%" flex={1}>
          <FlexBox
            flex={1}
            alignItems="center"
            position="relative"
            justifyContent="center"
          >
            <Box position="absolute" left="0">
              <Logo />
            </Box>
            <NavListElements />
          </FlexBox>
          {/*DODO - add i18n */}
          {/*<LanguageChange items={languageList} />*/}
        </FlexBox>
      </Box>
    </ContentContainer>
  );
};
