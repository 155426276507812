export const extractAndDecodeFileName = (url?: string): string | undefined => {
  if (url !== undefined) {
    const path = url.split("?")[0];
    const segments = path.split("/");
    const encodedFileName = segments.pop() || "";

    const decodedSegment = decodeURIComponent(encodedFileName);

    return decodedSegment.split("/").pop()?.split(".")[0];
  }
  return undefined;
};
