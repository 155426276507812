const API_KEY = process.env.REACT_APP_API_KEY;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
const APP_ID = process.env.REACT_APP_APP_ID;
const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;

const ADMIN_LOGIN = process.env.REACT_APP_ADMIN_LOGIN;
const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD;

export {
  API_KEY,
  AUTH_DOMAIN,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
  ADMIN_LOGIN,
  ADMIN_PASSWORD,
};
