import React from "react";
import { WithLayout } from "../../common/components/layout/WithLayout";
import { Box, FlexBox } from "../../common/components/Box";
import { ContentContainer } from "../../common/components/layout/ContentContainer.styled";
import { Gallery } from "../../common/components/gallery/Gallery";
import { SectionTitle } from "./GalleryDrawingPage.styled";
import { useGalleryDrawingPage } from "./hooks/useGalleryDrawingPage";
import { UploadDrawingsForm } from "../adminPanelPage/UploadDrawingsForm";
import { useAuth } from "../../modules/auth/AuthContext";

export const GalleryDrawingPage = () => {
  const auth = useAuth();
  const { drawingsList, deletePaintingImage, handleRefreshList } =
    useGalleryDrawingPage();

  return (
    <Box background="#4B4B4B">
      <WithLayout>
        <ContentContainer>
          {auth?.user && (
            <>
              <h1>Dodaj rysynek:</h1>
              <UploadDrawingsForm
                galleryFolder="drawings"
                onRefresh={handleRefreshList}
              />
            </>
          )}
          <FlexBox mt="80px" justifyContent="center">
            <SectionTitle>Rysunki</SectionTitle>
          </FlexBox>
          <FlexBox mt="40px">
            <Gallery
              type="drawing"
              items={drawingsList}
              onDelete={deletePaintingImage}
            />
          </FlexBox>
        </ContentContainer>
      </WithLayout>
    </Box>
  );
};
