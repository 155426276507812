import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: "155px",
  [theme.breakpoints.down("md")]: {
    marginTop: "55px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "30px",
  },
}));

export const PaintingTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "montserrat",
  fontSize: "18px",
  color: "white",
  fontWeight: "600",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

export const PaintingTitleLine = styled(Typography)(({ theme }) => ({
  width: "72px",
  height: "2px",
  background: "#fff",
  [theme.breakpoints.down("sm")]: {
    width: "36px",
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "tinos",
  lineHeight: "100px",
  color: "#fff",
  fontSize: "80px",
  textTransform: 'capitalize',
  [theme.breakpoints.down("md")]: {
    fontSize: "58px",
    lineHeight: "66px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "40px",
    lineHeight: "40px",
  },
}));
