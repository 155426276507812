import React from "react";
import { WithLayout } from "../common/components/layout/WithLayout";
import { Box } from "../common/components/Box";
import { HOME_PAGE } from "./paths";
import { useNavigate } from "react-router-dom";

export const ContactPage = () => {
  const navigate = useNavigate();
  const redirectTo = () => {
    navigate(HOME_PAGE);
  };

  return (
    <WithLayout>
      <Box>
        ContactPage
        <button onClick={redirectTo}>Home</button>
      </Box>
    </WithLayout>
  );
};
