import React from "react";
import { Box, FlexBox } from "../../../common/components/Box";
import {
  Container,
  PaintingTitle,
  PaintingTitleLine,
  SectionTitle,
} from "./HomePageTitle.styled";

interface Props {
  paintingTitle?: string;
}

export const HomePageTitle = ({ paintingTitle }: Props) => {
  return (
    <Container>
      <FlexBox gridGap="30px" alignItems="center">
        <PaintingTitleLine />
        <PaintingTitle>{paintingTitle ? `${paintingTitle}.` : ""}</PaintingTitle>
      </FlexBox>
      <Box>
        <SectionTitle>Odkryj świat artystycznych wyrażeń</SectionTitle>
      </Box>
    </Container>
  );
};
