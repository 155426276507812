import React from "react";
import { FlexBox } from "../../../common/components/Box";
import { PhoneIcon } from "../../../common/icons/PhoneIcon";
import MailIcon from "@mui/icons-material/Mail";
import PlaceIcon from "@mui/icons-material/Place";
import { Phone as MuiPhoneIcon } from "@mui/icons-material";
import {
  ContactDetails,
  ContactInfo,
  Container,
  PhoneIconElement,
  SectionSubTitle,
  SectionTitle,
  TitleLine,
} from "./ContactSection.styled";
import { useMediaQuery, useTheme } from "@mui/material";
import { Element } from "react-scroll";

export const ContactSection = () => {
  const smallPhoneVariant = useMediaQuery("(max-width:1000px)");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Element name="contact">
      <Container>
        <PhoneIconElement>
          {smallPhoneVariant ? (
            <PhoneIcon width="262" height="262" />
          ) : (
            <PhoneIcon />
          )}
        </PhoneIconElement>
        <FlexBox flex={3} alignItems="center">
          <FlexBox flexDirection="column" gridGap="20px">
            <FlexBox gridGap="30px" alignItems="center">
              <TitleLine />
              <SectionSubTitle>KONTAKT</SectionSubTitle>
            </FlexBox>
            <SectionTitle>Skontaktuj się ze mną</SectionTitle>
            <FlexBox flexDirection="column" gridGap="14px">
              <ContactDetails>
                <MailIcon
                  fontSize={sm ? "small" : undefined}
                  style={{ color: "#fff" }}
                />
                <ContactInfo>IreneuszRolewskiKontakt@gmail.com</ContactInfo>
              </ContactDetails>
              <ContactDetails>
                <MuiPhoneIcon
                  fontSize={sm ? "small" : undefined}
                  style={{ color: "#fff" }}
                />
                <ContactInfo>
                  <a
                    href="tel:+48503430767"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    +48 503 430 767
                  </a>
                </ContactInfo>
              </ContactDetails>
              <ContactDetails>
                <PlaceIcon
                  fontSize={sm ? "small" : undefined}
                  style={{ color: "#fff" }}
                />
                <ContactInfo>Skierniewice</ContactInfo>
              </ContactDetails>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </Container>
    </Element>
  );
};
