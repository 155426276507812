import React from "react";

interface Props {
  width?: string;
  height?: string;
}

export const CloseBurgerMenuIcon = ({ width = "17", height = "18" }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.778 14.42L11.276 8.91696L16.776 3.41496L13.949 0.584961L8.44597 6.08696L2.94397 0.584961L0.115967 3.41496L5.61597 8.91696L0.115967 14.419L2.94597 17.247L8.44597 11.745L13.946 17.247L16.778 14.42Z"
      fill="#4B4B4B"
    />
  </svg>
);
