import { useEffect, useState } from "react";
import { db, storage } from "../../../common/api/firebase";
import { ImageElement } from "../../galleryPaintingPage/types";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";

export const useGalleryDrawingPage = () => {
  const [drawingsList, setDrawingsList] = useState<
    ImageElement[] | undefined
  >();

  const fetchImagesList = async () => {
    const querySnapshot = await getDocs(
      collection(db, `images/archive/drawings`),
    );
    const images: ImageElement[] = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as Omit<ImageElement, "id">),
    }));
    setDrawingsList(images);
  };

  useEffect(() => {
    fetchImagesList();
  }, []);

  const handleRefreshList = () => {
    fetchImagesList();
  };

  const deletePaintingImage = async (image: ImageElement): Promise<void> => {
    try {
      // Usunięcie dokumentu z Firestore
      await deleteDoc(doc(db, "images/archive/drawings", image.id));

      // Utworzenie referencji do pliku w Storage
      const imageRef = ref(storage, image.imageUrl);

      // Usunięcie pliku obrazu z Storage
      await deleteObject(imageRef);

      // Aktualizacja listy obrazów
      await fetchImagesList();
    } catch (error) {
      console.error("Wystąpił błąd podczas usuwania obrazu: ", error);
    }
  };

  return {
    drawingsList,
    deletePaintingImage,
    handleRefreshList
  };
};
