import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { FlexBox } from "../../../common/components/Box";

export const Container = styled(FlexBox)(() => ({
  marginTop: "60px",
  justifyContent: "center",
  alignItems: "center",
  height: "490px",
  "@media (max-width: 740px)": {
    flexDirection: "column-reverse",
    alignItems: "center",
  },
}));
export const TitleLine = styled(Box)(({ theme }) => ({
  width: "72px",
  height: "2px",
  background: "#fff",
  [theme.breakpoints.down("sm")]: {
    width: "36px",
  },
}));

export const SectionSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "montserrat",
  fontSize: "18px",
  color: "white",
  fontWeight: "600",
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "tinos",
  lineHeight: "100px",
  color: "#fff",
  fontSize: "60px",
  marginBottom: "16px",
  textTransform: "capitalize",
  "@media (max-width: 1000px)": {
    fontSize: "50px",
    lineHeight: "66px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "40px",
    lineHeight: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
    lineHeight: "20px",
  },
  "@media (max-width: 310px)": {
    fontSize: "20px",
    lineHeight: "15px",
  },
}));

export const ContactInfo = styled(Typography)(({theme}) => ({
  color: "#FFF",
  fontFamily: "montserrat",
  fontSize: "16px",
  fontWeight: "400",
  wordWrap: "break-word",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

export const ContactDetails = styled(FlexBox)(({theme}) => ({
  flexWrap: "wrap",
  gridGap: "20px",
  [theme.breakpoints.down("sm")]: {
    gridGap: "8px",
  },
}));

export const PhoneIconElement = styled(Typography)(() => ({
  flex: 2,
  "@media (max-width: 740px)": {
    paddingTop: "100px",
  },
}));
