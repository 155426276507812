import React from "react";
import { AboutMe } from "./aboutMe/AboutMe";
import { Box } from "@mui/material";
import { NavigationBar } from "../../common/components/navigation/NavigationBar";
import { HomePageTitle } from "./homePageTitle/HomePageTitle";
import { ContactSection } from "./contactSection/ContactSection";
import { Footer } from "../../common/components/layout/footer/Footer";
// import { Gallery } from "./gallery/Gallery";
import { BackgroundContainer, ImageContainer } from "./HomePage.styled";
import { ContentContainer } from "../../common/components/layout/ContentContainer.styled";
import { useHomePage } from "./hooks/useHomePage";
import { extractAndDecodeFileName } from "../../common/heplers/extractAndDecodeFileName";

export const HomePage = () => {
  const { backgroundUrl } = useHomePage();

  return (
    <Box>
      <BackgroundContainer>
        <ImageContainer url={backgroundUrl}>
          <NavigationBar />
          <ContentContainer>
            <HomePageTitle
              paintingTitle={extractAndDecodeFileName(backgroundUrl)}
            />
            <AboutMe />
          </ContentContainer>
        </ImageContainer>
        <ContentContainer>
          {/*<Gallery />*/}
          <ContactSection />
        </ContentContainer>
      </BackgroundContainer>
      <Footer />
    </Box>
  );
};
