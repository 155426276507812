import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ItemsContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  margin: "0 auto",
  rowGap: "40px",
  columnGap: "20px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "15px",
    rowGap: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
    rowGap: "20px",
  },
  marginBottom: "40px",
}));

export const GalleryItem = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "350px",
  height: "350px",
  border: "2px solid #3d3d3d",
  objectFit: "contain",
  cursor: "pointer",
  "&:hover > div": {
    opacity: 1,
    visibility: "visible",
  },
  [theme.breakpoints.down("lg")]: {
    width: "260px",
    height: "260px",
  },
  "@media (max-width: 300px)": {
    width: "170px",
    height: "170px",
  },
}));
export const StyledImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

export const InfoOverlay = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "10px",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  opacity: 0,
  visibility: "hidden",
  transition: "opacity 0.3s, visibility 0.3s",
  zIndex: 2,
}));
