import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "tinos",
  lineHeight: "100px",
  color: "#fff",
  fontSize: "60px",
  marginBottom: "16px",
  textTransform: "capitalize",
  [theme.breakpoints.down("lg")]: {
    fontSize: "50px",
    lineHeight: "66px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "40px",
    lineHeight: "40px",
  },
}));
