import React from "react";
import { WithLayout } from "../../common/components/layout/WithLayout";
import { Box, FlexBox } from "../../common/components/Box";
import { ContentContainer } from "../../common/components/layout/ContentContainer.styled";
import {
  GalleryItem,
  ItemsContainer,
  SectionTitle,
} from "./GalleryPage.styled";
import { useGalleryPage } from "./hooks/useGalleryPage";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GALLERY_DRAWINGS_PAGE, GALLERY_PAINTINGS_PAGE } from "../paths";

export const GalleryPage = () => {
  const { drawingUrl, paintingUrl } = useGalleryPage();
  const navigate = useNavigate();

  return (
    <Box background="#4B4B4B">
      <WithLayout>
        <ContentContainer>
          <FlexBox mt="80px" justifyContent="center">
            <SectionTitle>Archiwum</SectionTitle>
          </FlexBox>
          <ItemsContainer>
            <FlexBox
              flexDirection="column"
              alignItems="center"
              onClick={() => navigate(GALLERY_PAINTINGS_PAGE)}
            >
              <GalleryItem url={paintingUrl}>
                {paintingUrl === undefined && (
                  <FlexBox
                    height="100%"
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress style={{ color: "white" }} size="30%" />
                  </FlexBox>
                )}
              </GalleryItem>
              <Box mt="10px">
                <Typography
                  color="#FFF"
                  fontFamily="tions"
                  fontSize="20px"
                  lineHeight="30px"
                >
                  Obrazy
                </Typography>
              </Box>
            </FlexBox>
            <FlexBox
              flexDirection="column"
              alignItems="center"
              onClick={() => navigate(GALLERY_DRAWINGS_PAGE)}
            >
              <GalleryItem url={drawingUrl}>
                {drawingUrl === undefined && (
                  <FlexBox
                    height="100%"
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress style={{ color: "white" }} size='30%' />
                  </FlexBox>
                )}
              </GalleryItem>
              <Box mt="10px">
                <Typography
                  color="#FFF"
                  fontFamily="tions"
                  fontSize="20px"
                  lineHeight="30px"
                >
                  Rysunki
                </Typography>
              </Box>
            </FlexBox>
          </ItemsContainer>
        </ContentContainer>
      </WithLayout>
    </Box>
  );
};
