import React, { useState } from "react";
import { Box, FlexBox } from "../../Box";
import { Logo } from "../../logo/Logo";
import { ContentContainer } from "./MobileNavigation.styled";
import { BurgerMenu } from "../burgerMenu/BurgerMenu";
import { MobileNavList } from "./mobileNavList/MobileNavList";

export const MobileNavigation = () => {
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);

  const handleOpenMenu = () => setDisplayMenu(true);
  const handleCloseMenu = () => setDisplayMenu(false);

  return (
    <Box
      height={displayMenu ? "100vh" : undefined}
      background={displayMenu && "#fff"}
    >
      <ContentContainer>
        <FlexBox felxDifection="column">
          <FlexBox
            alignItems="center"
            height="62px"
            justifyContent="space-between"
            flex={1}
          >
            {displayMenu ? <Logo color="#4B4B4B" /> : <Logo />}
            <BurgerMenu
              handleOpenMenu={handleOpenMenu}
              handleCloseMenu={handleCloseMenu}
              isOpen={displayMenu}
            />
          </FlexBox>
        </FlexBox>
      </ContentContainer>
      {displayMenu && <MobileNavList />}
    </Box>
  );
};
