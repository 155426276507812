import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../../../common/api/firebase";

export const useHomePage = () => {
  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>();

  const imagesListRef = ref(storage, "images/home_page/background");
  const location = useLocation();

  // TODO - remove when section contact will done
  useEffect(() => {
    if (location.pathname === "/" && location.hash === "#contact") {
      scroller.scrollTo("contact", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);


  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await listAll(imagesListRef);
        const promises = response.items.map((item) => getDownloadURL(item));
        const urls = await Promise.all(promises);
        setBackgroundUrl(urls[0]);
      } catch (error) {
        console.error("Błąd podczas pobierania URL-i obrazów: ", error);
        setBackgroundUrl(undefined)
      }
    };

    fetchImages();
  }, []);

  return {
    backgroundUrl,
  };
};
