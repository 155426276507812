import React from "react";
import { FlexBox } from "../../../Box";
import { NavElement } from "../../../layout/NavElementType";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

interface Props {
  items?: NavElement[];
}

export const MobileNavDropdown = ({ items }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const redirectTo = (path?: string) => {
    if (path) {
      navigate(path);
    }
  };
  return (
    <FlexBox flexDirection="column">
      {items?.map((item) => {
        const isSelected = item.path === location.pathname;
        return (
          <FlexBox
            alignItems="center"
            p="10px"
            key={item.id}
            onClick={() => redirectTo(item.path)}
          >
            <Typography
              fontFamily="montserrat"
              fontSize="14px"
              color="#000"
              fontWeight={isSelected ? "bold" : undefined}
            >
              {item.name}
            </Typography>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};
