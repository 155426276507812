import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ImageContainer = styled(Box)<{ url: string | undefined }>(
  ({ url }) => ({
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(330deg, rgba(75, 75, 75, 0.00) 91.06%, #4b4b4b 108.93%), " +
      "linear-gradient(180deg, rgba(75, 75, 75, 0.00) 0%, #4b4b4b 91.38%), " +
      `url(${url})`,
    backgroundPosition: "center",
    transition: "backgroundImage 10s ease",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }),
);

export const BackgroundContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  background: "#4b4b4b",
  paddingBottom: "60px",
}));

export const ContentContainer = styled(Box)(() => ({
  maxWidth: "1140px",
  margin: "0 auto",
  height: "100%",
  paddingLeft: "20px",
  paddingRight: "20px",
}));
