import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import { Routing } from "./pages/Routing";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./modules/auth/AuthContext";

function App() {
  return (
    <div>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routing />
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
