import React from "react";
import { Box, FlexBox } from "../../../common/components/Box";
import { Typography } from "@mui/material";
import {
  AboutMeSection,
  Avatar,
  AvatarContainer,
  Container,
  SectionSubTitle,
  SectionTitle,
  TitleLine,
} from "./AboutMe.styled";
import { useAboutMe } from "./hooks/useAboutMe";

export const AboutMe = () => {
  const { avatarUrl } = useAboutMe();
  return (
    <Container>
      <AboutMeSection>
        <FlexBox
          flexDirection="column"
          marginBottom="20px"
          justifyContent="center"
          flex={2}
        >
          <Box>
            <FlexBox gridGap="30px" alignItems="center">
              <TitleLine />
              <SectionSubTitle>O &nbsp; MNIE</SectionSubTitle>
            </FlexBox>
            <SectionTitle>Kim jestem?</SectionTitle>
          </Box>
          <FlexBox gridGap="8px" flexDirection="column">
            <Box>
              <Typography
                color="#FFF"
                fontFamily="montserrat"
                fontSize="16px"
                lineHeight="30px"
              >
                Ireneusz Mateusz Rolewski (ur. 22 października 1993 r. w
                Łowiczu) - malarz i rysownik. Absolwent Łódzkiej Akademii Sztuk
                Pięknych im. Władysława Strzemińskiego w Łodzi na Wydziale
                Grafiki i Malarstwa na kierunku Malarstwo.
              </Typography>
            </Box>
            <Box>
              <Typography
                color="#FFF"
                fontFamily="montserrat"
                fontSize="16px"
                lineHeight="30px"
              >
                Specjalizuje się w malarstwie i rysunku, posiadam cechy
                naturalizmu i ekspresjonizmu. Najczęstszym tematem moich prac są
                portrety, jednak równie chętnie podchodzę do scen rodzajowych,
                pejzażów i martwych natur. Lubię eksperymentować z formą obrazu,
                a dokładniej z gęstością, ilością i jakością mediów malarskich,
                oraz ze sposobem nakładania ich na różnie przygotowane
                podobrazie. Wielką przyjemność sprawia mi projektowanie rzeźb
                kinetycznych, których idea opiera się zazwyczaj na rozwiązaniach
                świata przyrody. Interesuje się wszelkimi zjawiskami
                paranormalnymi, przyrodą, malarstwem XIX i XX wiecznym i
                młodopolskim oraz iluzją wizualną.
              </Typography>
            </Box>
          </FlexBox>
        </FlexBox>
        <AvatarContainer>
          <Avatar url={avatarUrl} />
        </AvatarContainer>
      </AboutMeSection>
    </Container>
  );
};
