import React from "react";
import { Typography } from "@mui/material";
import { LogoItem } from "./Logo.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { HOME_PAGE } from "../../../pages/paths";

interface Props {
  color?: string;
}

export const Logo = ({ color = "#fff" }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage: boolean = location.pathname === HOME_PAGE;
  const redirectTo = () => {
    if (!isHomePage) {
      navigate(HOME_PAGE);
    }
  };
  return (
    <LogoItem isPointer={!isHomePage} onClick={redirectTo}>
      <Typography
        fontFamily="tinos"
        fontWeight="bold"
        fontSize="20px"
        color={color}
      >
        ROLEWSKI
      </Typography>
    </LogoItem>
  );
};
