import React from "react";

interface Props {
  width?: string;
  height?: string;
}

export const BurgerMenuIcon = ({ width = "18", height = "12" }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12V10H18V12H0ZM0 7V5H18V7H0ZM0 2V0H18V2H0Z" fill="white" />
  </svg>
);
