import React from "react";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "./paths";
import { WithLayout } from "../common/components/layout/WithLayout";

export const AboutMePage = () => {
  const navigate = useNavigate();
  const redirectToAnotherPage = () => {
    navigate(HOME_PAGE);
  };

  return (
    <WithLayout>
      about me
      <button onClick={redirectToAnotherPage}>Home</button>
    </WithLayout>
  );
};
