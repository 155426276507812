import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ContentContainer = styled(Box)(() => ({
  maxWidth: "1140px",
  margin: "0 auto",
  height: "100%",
  paddingLeft: "20px",
  paddingRight: "20px",
}));
