import { useEffect, useState } from "react";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../../../common/api/firebase";

export const useGalleryPage = () => {
  const [paintingUrl, setPaintingUrl] = useState<string | undefined>();
  const [drawingUrl, setDrawingUrls] = useState<string | undefined>();

  const fetchDrawing = async () => {
    try {
      const response = await listAll(
        ref(storage, "images/gallery_page/drawing"),
      );
      const promises = response.items.map((item) => getDownloadURL(item));
      const urls = await Promise.all(promises);
      setDrawingUrls(urls[0]);
    } catch (error) {
      console.error("Błąd podczas pobierania URL-i rysunku: ", error);
      setDrawingUrls(undefined);
    }
  };

  const fetchPainting = async () => {
    try {
      const response = await listAll(
        ref(storage, "images/gallery_page/painting"),
      );
      const promises = response.items.map((item) => getDownloadURL(item));
      const urls = await Promise.all(promises);
      setPaintingUrl(urls[0]);
    } catch (error) {
      console.error("Błąd podczas pobierania URL-i obrazu: ", error);
      setPaintingUrl(undefined);
    }
  };

  useEffect(() => {
    fetchPainting();
    fetchDrawing();
  }, []);

  return {
    drawingUrl,
    paintingUrl,
  };
};
