import React, { useState } from "react";
import { NavItem, NavList } from "./NavigationBar.styled";
import { Box, FlexBox } from "../../Box";
import { Typography } from "@mui/material";
import { DropdownMenu } from "./DropdownMenu";
import { NavElement } from "../../layout/NavElementType";
import { useLocation, useNavigate } from "react-router-dom";
import { navElements } from "../../layout/navElements";
import { ArrowDown, ArrowUp } from "../../../icons";

export const NavListElements = () => {
  const [hoveredItem, setHoveredItem] = useState<NavElement | null>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const redirectTo = (path?: string) => {
    if (path) {
      navigate(path);
    }
  };
  return (
    <NavList>
      {navElements.map((item) => {
        let isSelected: boolean;
        const hasDropdown = Boolean(item.navElements?.length);

        const handleMouseEnter = () => {
          if (hasDropdown) {
            setHoveredItem(item);
          }
        };

        const handleMouseLeave = () => {
          if (hasDropdown) {
            setHoveredItem(null);
          }
        };
        const onItemClick = () => {
          if (item?.clickable !== false) {
            redirectTo(item.path);
          }
        };

        if (item.navElements) {
          isSelected = location.pathname.startsWith(item.path || "");
        } else {
          isSelected = item.path === location.pathname;
        }

        return (
          <NavItem
            key={item.id}
            onClick={onItemClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Box position="relative">
              <FlexBox mt="2px">
                <Typography
                  fontFamily="montserrat"
                  fontSize="16px"
                  fontWeight={isSelected ? "bold" : undefined}
                  color="#fff"
                >
                  {item.name}
                </Typography>
                <FlexBox alignItems="center" ml="11px">
                  {hasDropdown && (hoveredItem ? <ArrowDown /> : <ArrowUp />)}
                </FlexBox>
              </FlexBox>
              {hoveredItem === item && (
                <DropdownMenu items={item.navElements} />
              )}
            </Box>
          </NavItem>
        );
      })}
    </NavList>
  );
};
