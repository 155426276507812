import React, { ChangeEvent, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { storage, db } from "../../common/api/firebase";
import { Box, FlexBox } from "../../common/components/Box";
import { Button } from "@mui/material";

interface LocalizedText {
  pl: string;
  en: string;
  de: string;
}

interface Props {
  galleryFolder: "drawings" | "paintings" | "mainPageGallery";
  onRefresh: () => void;
}

export const UploadDrawingsForm = ({ galleryFolder, onRefresh }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState<LocalizedText>({ pl: "", en: "", de: "" });
  const [description, setDescription] = useState<LocalizedText>({
    pl: "",
    en: "",
    de: "",
  });

  const handleSubmit = async () => {
    if (!file) return;

    const imagesRef = ref(
      storage,
      `images/archive/${galleryFolder}/${file.name}`,
    );
    const snapshot = await uploadBytes(imagesRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    try {
      const trimmedTitle = {
        pl: title.pl.trim(),
        en: title.en.trim(),
        de: title.de.trim(),
      };
      const trimmedDescription = {
        pl: description.pl.trim(),
        en: description.en.trim(),
        de: description.de.trim(),
      };

      await addDoc(collection(db, `images/archive/${galleryFolder}`), {
        title: trimmedTitle,
        description: trimmedDescription,
        imageUrl: downloadURL,
      });

      setFile(null);
      setTitle({ pl: "", en: "", de: "" });
      setDescription({ pl: "", en: "", de: "" });

      onRefresh();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    setFile(files[0]);
  };

  const handleTitleChange = (
    e: ChangeEvent<HTMLInputElement>,
    lang: keyof LocalizedText,
  ) => {
    setTitle({ ...title, [lang]: e.target.value });
  };

  const handleDescriptionChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    lang: keyof LocalizedText,
  ) => {
    setDescription({ ...description, [lang]: e.target.value });
  };

  return (
    <FlexBox flexDirection="column">
      <Box py="16px">
        <div>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="raised-button-file"
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              Wybierz plik
            </Button>
          </label>
          <Box mx="16px">{file?.name || "Nie wybrano zdjęcia"}</Box>
        </div>
      </Box>
      <FlexBox flexDirection="column" gridGap="16px">
        <FlexBox flexDirection="column">
          <Box>Tytuł PL:</Box>
          <Box>
            <input
              type="text"
              value={title.pl}
              placeholder="Tytuł PL"
              onChange={(e) => handleTitleChange(e, "pl")}
              required
            />
          </Box>
          <Box>Opis PL:</Box>
          <Box>
            <textarea
              value={description.pl}
              placeholder="Opis PL"
              onChange={(e) => handleDescriptionChange(e, "pl")}
              required
            />
          </Box>
        </FlexBox>
        <FlexBox flexDirection="column">
          <Box>Tytuł EN:</Box>
          <Box>
            <input
              value={title.en}
              type="text"
              placeholder="Tytuł EN"
              onChange={(e) => handleTitleChange(e, "en")}
              required
            />
          </Box>
          <Box>Opis EN:</Box>
          <Box>
            <textarea
              value={description.en}
              placeholder="Opis EN"
              onChange={(e) => handleDescriptionChange(e, "en")}
              required
            />
          </Box>
        </FlexBox>
        <FlexBox flexDirection="column">
          <Box>Tytuł DE:</Box>
          <Box>
            <input
              value={title.de}
              type="text"
              placeholder="Tytuł DE"
              onChange={(e) => handleTitleChange(e, "de")}
              required
            />
          </Box>
          <Box>Opis DE:</Box>
          <Box>
            <textarea
              value={description.de}
              placeholder="Opis DE"
              onChange={(e) => handleDescriptionChange(e, "de")}
              required
            />
          </Box>
        </FlexBox>
      </FlexBox>
      <Box>
        <Button variant="contained" disabled={!file} onClick={handleSubmit}>
          Dodaj zdjęcie
        </Button>
      </Box>
    </FlexBox>
  );
};
