import React from "react";
import { Box } from "../Box";
import { NavigationBar } from "../navigation/NavigationBar";
import { Footer } from "./footer/Footer";

type Props = {
  children?: React.ReactNode;
};

export const WithLayout = ({ children }: Props) => {
  return (
    <>
      <NavigationBar />
      <Box minHeight='100vh'>{children}</Box>
      <Footer />
    </>
  );
};
