import { NavElement } from "./NavElementType";
import {
  GALLERY_DRAWINGS_PAGE,
  GALLERY_PAGE,
  GALLERY_PAINTINGS_PAGE,
} from "../../../pages/paths";

export const navElements: NavElement[] = [
  // {
  //     id: 1,
  //     name: "Start",
  //     path: HOME_PAGE,
  // },
  // {
  //     id: 2,
  //     name: "O mnie",
  //     path: ABOUT_ME_PAGE,
  // },
  {
    id: 3,
    name: "Archiwum",
    path: GALLERY_PAGE,
    clickable: false,
    navElements: [
      {
        id: 1,
        name: "Obrazy",
        path: GALLERY_PAINTINGS_PAGE,
      },
      {
        id: 2,
        name: "Rysunki",
        path: GALLERY_DRAWINGS_PAGE,
      },
    ],
  },
  {
    id: 4,
    path: `/#contact`,
    name: "Kontakt",
  },
];
