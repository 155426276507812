import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const DropdownList = styled(Box)(() => ({
  position: "absolute",
  top: "100%",
  left: "-29px",
  background: "#fff",
  minWidth: "113px",
  paddingTop: "5px",
  paddingBottom: "5px",
  borderRadius: "4px",
}));
