import { useState } from "react";

export const useGallery = () => {
  const [open, setOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState<string | null>(null);

  const handleOpen = (url: string) => {
    setSelectedImg(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    selectedImg,
    handleOpen,
    handleClose,
  };
};
