import { styled } from "@mui/material/styles";
import { FlexBox } from "../../Box";

export const NavItem = styled(FlexBox)(() => ({
  cursor: "pointer",
  width: "116px",
  justifyContent: "center",
}));

export const NavList = styled(FlexBox)(({ theme }) => ({
  alignItems: "center",
  marginRight: '10px',
  marginLeft: '10px',
  [theme.breakpoints.down("md")]: {
    paddingLeft: "120px",
  },
}));

