import React from "react";
import { Box, FlexBox } from "../Box";
import {
  GalleryItem,
  InfoOverlay,
  ItemsContainer,
  StyledImage,
} from "./Gallery.styled";
import {
  CircularProgress,
  Dialog,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useGallery } from "./useGallery";
import { ImageElement } from "../../../pages/galleryPaintingPage/types";
import { useAuth } from "../../../modules/auth/AuthContext";

interface Props {
  items?: ImageElement[];
  type: "painting" | "drawing";
  onDelete: (image: ImageElement) => void;
}

export const Gallery = ({ items, type, onDelete }: Props) => {
  const { open, selectedImg, handleOpen, handleClose } = useGallery();
  const auth = useAuth();
  const mobileVersion = useMediaQuery("(max-width:780px)");

  const lang = "pl";

  return (
    <>
      {items === undefined && (
        <FlexBox flex={1} justifyContent="center">
          <CircularProgress style={{ color: "white" }} size={100} />
        </FlexBox>
      )}
      {items && items.length > 0 && (
        <ItemsContainer>
          {items.map((item, index) => (
            <FlexBox key={index} flexDirection="column">
              <GalleryItem onClick={() => handleOpen(item.imageUrl)}>
                <StyledImage
                  loading="lazy"
                  src={item.imageUrl}
                  alt={item.title[lang]}
                />
                {!mobileVersion && item.title[lang].length > 0 && (
                  <InfoOverlay>
                    <FlexBox
                      gridGap="16px"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Box>
                        <Typography
                          color="#FFF"
                          fontFamily="tions"
                          fontSize="30px"
                          lineHeight="30px"
                        >
                          {item.title[lang] ? `"${item.title[lang]}"` : ""}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          color="#FFF"
                          fontFamily="montserrat"
                          fontSize="16px"
                        >
                          {item.description[lang]
                            ? `"${item.description[lang]}"`
                            : ""}
                        </Typography>
                      </Box>
                    </FlexBox>
                  </InfoOverlay>
                )}
              </GalleryItem>
              {auth?.user && (
                <button onClick={() => onDelete(item)}>Usuń</button>
              )}
            </FlexBox>
          ))}
        </ItemsContainer>
      )}
      {items && items.length === 0 && (
        <FlexBox flex={1} justifyContent="center">
          <Box maxWidth="700px">
            <FlexBox flex={1} justifyContent="center" mb="30px">
              <Typography
                color="#FFF"
                fontFamily="montserrat"
                fontSize="30px"
                lineHeight="30px"
              >
                Archiwum w Przygotowaniu!
              </Typography>
            </FlexBox>
            <Typography
              color="#FFF"
              fontFamily="montserrat"
              fontSize="16px"
              lineHeight="30px"
            >
              {`
              Dziękuję za odwiedzenie mojej strony. Aktualnie trwają prace nad
              galerią, która wkrótce zaprezentuje moje prace
              ${type === "painting" ? "malarskie." : ""}
              ${type === "drawing" ? "rysunkowe." : ""}`}
            </Typography>
          </Box>
        </FlexBox>
      )}
      <Dialog open={open} onClose={handleClose}>
        {selectedImg && (
          <img
            src={selectedImg}
            alt="Enlarged pic"
            style={{ width: "100%", maxHeight: "90vh", objectFit: "contain" }}
          />
        )}
      </Dialog>
    </>
  );
};
