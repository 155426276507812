import styled from '@emotion/styled';
import {
    background,
    BackgroundProps,
    border,
    BorderProps,
    color,
    ColorProps,
    compose,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    shadow,
    ShadowProps,
    space,
    SpaceProps,
    textAlign,
    TextAlignProps,
    typography,
    TypographyProps,
    grid,
    GridProps,
} from 'styled-system';
import React from 'react';

export type BoxProps = FlexboxProps &
    BackgroundProps &
    BorderProps &
    ColorProps &
    LayoutProps &
    PositionProps &
    ShadowProps &
    SpaceProps &
    TextAlignProps &
    TypographyProps &
    GridProps;

export const boxStyledSystem = compose(
    background,
    border,
    color,
    flexbox,
    layout,
    position,
    shadow,
    space,
    textAlign,
    typography,
    grid,
);

export const Box: React.ElementType = styled.div<BoxProps>((props) => boxStyledSystem(props));
export const FlexBox = styled(Box)({ display: 'flex' });
