import { useEffect, useState } from "react";
import { db, storage } from "../../../common/api/firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { ImageElement } from "../types";
import { deleteObject, ref } from "firebase/storage";

export const useGalleryPaintingPage = () => {
  const [paintingsList, setPaintingsList] = useState<
    ImageElement[] | undefined
  >();

  const fetchImagesList = async () => {
    const querySnapshot = await getDocs(
      collection(db, `images/archive/paintings`),
    );
    const images: ImageElement[] = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as Omit<ImageElement, "id">),
    }));
    setPaintingsList(images);
  };

  useEffect(() => {
    fetchImagesList();
  }, []);

  const handleRefreshList = () => {
    fetchImagesList();
  };
  const deletePaintingImage = async (
    image: ImageElement,
  ): Promise<void> => {
    try {
      // Usunięcie dokumentu z Firestore
      await deleteDoc(doc(db, "images/archive/paintings", image.id));

      // Utworzenie referencji do pliku w Storage
      const imageRef = ref(storage, image.imageUrl);

      // Usunięcie pliku obrazu z Storage
      await deleteObject(imageRef);

      // Aktualizacja listy obrazów
      await fetchImagesList();
    } catch (error) {
      console.error("Wystąpił błąd podczas usuwania obrazu: ", error);
    }
  };

  return {
    paintingsList,
    deletePaintingImage,
    handleRefreshList
  };
};
