import React, { useState } from "react";
import { Box, FlexBox } from "../../../Box";
import { navElements } from "../../../layout/navElements";
import { Typography } from "@mui/material";
import { NavElement } from "../../../layout/NavElementType";
import { MobileNavDropdown } from "./MobileNavDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowDown, ArrowUp } from "../../../../icons";

export const MobileNavList = () => {
  const [selectedItem, setSelectedItem] = useState<NavElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const redirectTo = (path?: string) => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <FlexBox flexDirection="column">
      {navElements.map((item) => {
        const hasDropdown = Boolean(item.navElements?.length);
        let isSelected: boolean;

        if (item.navElements) {
          isSelected = location.pathname.startsWith(item.path || "");
        } else {
          isSelected = item.path === location.pathname;
        }

        const handleDropdown = (item: NavElement) => {
          if (hasDropdown) {
            if (selectedItem) {
              setSelectedItem(null);
            } else {
              setSelectedItem(item);
            }
          }
        };

        const handleClickElement = (item: NavElement) => {
          if (item?.clickable !== false) {
            redirectTo(item.path);
          } else {
            handleDropdown(item);
          }
        };

        return (
          <Box onClick={() => handleClickElement(item)} key={item.id}>
            <FlexBox
              height="40px"
              pl="20px"
              background={isSelected ? "#FFF1CA" : undefined}
              alignItems="center"
            >
              <FlexBox flex={1} justifyContent="space-between">
                <Typography
                  fontWeight="500"
                  fontFamily="montserrat"
                  fontSize="16px"
                  color="#4B4B4B"
                >
                  {item.name}
                </Typography>
                <FlexBox alignItems="center" px="10px">
                  {hasDropdown &&
                    (selectedItem ? (
                      <ArrowDown color="#4B4B4B" />
                    ) : (
                      <ArrowUp color="#4B4B4B" />
                    ))}
                </FlexBox>
              </FlexBox>
            </FlexBox>
            {selectedItem === item && (
              <Box pl="20px">
                <MobileNavDropdown items={item.navElements} />
              </Box>
            )}
          </Box>
        );
      })}
    </FlexBox>
  );
};
