import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  height: "427px",
  paddingTop: "74px",
  paddingBottom: "74px",
}));

export const BackgroundContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  background: "#3d3d3d",
}));

export const ContentContainer = styled(Box)(() => ({
  maxWidth: "1140px",
  margin: "0 auto",
  height: "100%",
  paddingLeft: "20px",
  paddingRight: "20px",
}));