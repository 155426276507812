import React from "react";

type Props = {
  width?: string;
  height?: string;
};
export const PhoneIcon = ({ width = "351", height = "351" }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 351 351"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M255.938 70.8143C233.649 63.4286 206.885 58.5 175.5 58.5C144.129 58.5 117.351 63.4286 95.0625 70.8143M255.938 70.8143C300.12 85.4393 326.649 109.717 336.375 124.312L299.812 160.875L255.938 131.625V70.8143ZM95.0625 70.8143C50.8804 85.4393 24.3506 109.717 14.625 124.312L51.1875 160.875L95.0625 131.625V70.8143ZM146.25 102.375V146.25M146.25 146.25L67.0702 225.43C61.5844 230.914 58.5017 238.353 58.5 246.109V263.25C58.5 271.008 61.5817 278.447 67.0671 283.933C72.5526 289.418 79.9924 292.5 87.75 292.5H263.25C271.008 292.5 278.447 289.418 283.933 283.933C289.418 278.447 292.5 271.008 292.5 263.25V246.109C292.498 238.353 289.416 230.914 283.93 225.43L204.75 146.25M146.25 146.25H204.75M204.75 146.25V102.375"
      stroke="white"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M175.5 248.625C191.654 248.625 204.75 235.529 204.75 219.375C204.75 203.221 191.654 190.125 175.5 190.125C159.346 190.125 146.25 203.221 146.25 219.375C146.25 235.529 159.346 248.625 175.5 248.625Z"
      stroke="white"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
