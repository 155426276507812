import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { FlexBox } from "../../../common/components/Box";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: "200px",
  [theme.breakpoints.down("md")]: {
    marginTop: "110px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "80px",
  },
}));

export const TitleLine = styled(Typography)(({ theme }) => ({
  width: "72px",
  height: "2px",
  background: "#fff",
  [theme.breakpoints.down("sm")]: {
    width: "36px",
  },
}));

export const SectionSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "montserrat",
  fontSize: "18px",
  color: "white",
  fontWeight: "600",
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "tinos",
  lineHeight: "100px",
  color: "#fff",
  fontSize: "80px",
  marginBottom: "16px",
  textTransform: 'capitalize',
  [theme.breakpoints.down("md")]: {
    fontSize: "58px",
    lineHeight: "66px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "40px",
    lineHeight: "40px",
  },
}));

export const AboutMeSection = styled(FlexBox)(({ theme }) => ({
  height: "710px",
  gridGap: "40px",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    height: "1300px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    height: "1500px",
  },
  '@media (max-width: 300px)': {
    height: "1700px",
  }
}));

export const Avatar = styled(FlexBox)<{url: string | undefined}>(({url}) => ({
  height: "100%",
  width: "100%",
  backgroundImage: `url(${url})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
}));

export const AvatarContainer = styled(FlexBox)(({theme}) => ({
  flex: 1.3,
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    flex: 2
  },
}));
