import React from "react";
import { Box, FlexBox } from "../../Box";
import { Logo } from "../../logo/Logo";
import {
  BackgroundContainer,
  Container,
  ContentContainer,
} from "./Footer.styled";
import { Typography } from "@mui/material";

export const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <BackgroundContainer>
      <ContentContainer>
        <Container>
          <FlexBox flexDirection="column" gridGap="110px">
            <FlexBox flexDirection="column" gridGap="40px">
              <Logo />
              <Box maxWidth="277px">
                <Typography
                  color="#FFF"
                  fontFamily="montserrat"
                  fontSize="20px"
                  lineHeight="32px"
                  textTransform="capitalize"
                >
                  Odkryj świat artystycznych wyrażeń.
                </Typography>
              </Box>
            </FlexBox>
            <Typography
              color="#FFF"
              fontFamily="montserrat"
              fontSize="16px"
              lineHeight="32px"
              textTransform="capitalize"
            >
              Copyright {currentYear} ROLEWSKI
            </Typography>
          </FlexBox>
        </Container>
      </ContentContainer>
    </BackgroundContainer>
  );
};
