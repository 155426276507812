import React from "react";
import { DesktopNavigation } from "./desktop/DesktopNavigation";
import { useMediaQuery } from "@mui/material";
import { MobileNavigation } from "./mobile/MobileNavigation";

export const NavigationBar = () => {
  const mobileVersion = useMediaQuery("(max-width:780px)");

  return mobileVersion ? <MobileNavigation /> : <DesktopNavigation />;
};
