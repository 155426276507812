import React from "react";
import { CloseBurgerMenuIcon } from "../../../icons/CloseBurgerMenuIcon";
import { BurgerMenuIcon } from "../../../icons/BurgerMenuIcon";
import { Box } from "../../Box";
import { BurgerMenuStyled } from "./BurgerMenu.styled";

interface Props {
  isOpen: boolean;
  handleOpenMenu: () => void;
  handleCloseMenu: () => void;
}

export const BurgerMenu = ({
  isOpen,
  handleOpenMenu,
  handleCloseMenu,
}: Props) => {
  return (
    <BurgerMenuStyled>
      {isOpen ? (
        <Box onClick={handleCloseMenu}>
          <CloseBurgerMenuIcon />
        </Box>
      ) : (
        <Box onClick={handleOpenMenu}>
          <BurgerMenuIcon />
        </Box>
      )}
    </BurgerMenuStyled>
  );
};
