import { NavElement } from "../../layout/NavElementType";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexBox } from "../../Box";
import { Typography } from "@mui/material";
import React from "react";
import { DropdownList } from "./DropdownMenu.styled";

export const DropdownMenu = ({ items }: { items?: NavElement[] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const redirectTo = (path?: string) => {
    if (path) {
      navigate(path);
    }
  };
  return (
    <DropdownList>
      {items &&
        items.map((item) => {
          const isSelected = item.path === location.pathname;
          return (
            <FlexBox
              alignItems="center"
              p="10px"
              key={item.id}
              onClick={() => redirectTo(item.path)}
              background={isSelected ? "#FFF1CA" : undefined}
            >
              <Typography
                fontFamily="montserrat"
                fontSize="14px"
                color="#000"
                fontWeight={isSelected ? "bold" : undefined}
              >
                {item.name}
              </Typography>
            </FlexBox>
          );
        })}
    </DropdownList>
  );
};
