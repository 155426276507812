import { useEffect, useState } from "react";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../../../../common/api/firebase";

export const useAboutMe = () => {
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  const imagesListRef = ref(storage, "images/home_page/avatar");

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await listAll(imagesListRef);
        const promises = response.items.map((item) => getDownloadURL(item));
        const urls = await Promise.all(promises);
        setAvatarUrl(urls[0]);
      } catch (error) {
        console.error("Błąd podczas pobierania URL-i obrazów: ", error);
        setAvatarUrl(undefined);
      }
    };

    fetchImages();
  }, []);

  return {
    avatarUrl,
  };
};
