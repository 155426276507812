import React from "react";
import { WithLayout } from "../../common/components/layout/WithLayout";
import { Box, FlexBox } from "../../common/components/Box";
import { ContentContainer } from "../../common/components/layout/ContentContainer.styled";
import { SectionTitle } from "./GalleryPaintingPage.styled";
import { Gallery } from "../../common/components/gallery/Gallery";
import { useGalleryPaintingPage } from "./hooks/useGalleryPaintingPage";
import { UploadDrawingsForm } from "../adminPanelPage/UploadDrawingsForm";
import { useAuth } from "../../modules/auth/AuthContext";

export const GalleryPaintingPage = () => {
  const auth = useAuth();
  const { paintingsList, deletePaintingImage, handleRefreshList } = useGalleryPaintingPage();

  return (
    <Box background="#4B4B4B">
      <WithLayout>
        <ContentContainer>
          {auth?.user && (
            <>
              <h1>Dodaj obraz:</h1>
              <UploadDrawingsForm galleryFolder="paintings" onRefresh={handleRefreshList}/>
            </>
          )}
          <FlexBox mt="80px" justifyContent="center">
            <SectionTitle>Obrazy</SectionTitle>
          </FlexBox>
          <FlexBox mt="40px">
            <Gallery
              type="painting"
              items={paintingsList}
              onDelete={deletePaintingImage}
            />
          </FlexBox>
        </ContentContainer>
      </WithLayout>
    </Box>
  );
};
