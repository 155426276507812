import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { FlexBox } from "../../common/components/Box";

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "tinos",
  lineHeight: "100px",
  color: "#fff",
  fontSize: "60px",
  marginBottom: "16px",
  textTransform: "capitalize",
  [theme.breakpoints.down("lg")]: {
    fontSize: "50px",
    lineHeight: "66px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "40px",
    lineHeight: "40px",
  },
}));

export const GalleryItem = styled(Box)(({ url }: { url?: string }) => ({
  width: "320px",
  height: "320px",
  border: "2px solid #3d3d3d",
  backgroundImage: `url("${url}")`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  cursor: "pointer",
  "@media (max-width: 1010px)": {
    width: "300px",
    height: "300px",
  },
  "@media (max-width: 1000px)": {
    width: "250px",
    height: "250px",
  },
}));

export const ItemsContainer = styled(FlexBox)(() => ({
  justifyContent: "center",
  gridGap: "20px",
  width: "100%",
  "@media (max-width: 830px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
