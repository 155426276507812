import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./HomePage/HomePage";
import {
  ABOUT_ME_PAGE,
  ADMIN_PANEL_PAGE,
  CONTACT_PAGE,
  GALLERY_DRAWINGS_PAGE,
  GALLERY_PAGE,
  GALLERY_PAINTINGS_PAGE,
  HOME_PAGE,
} from "./paths";
import { AboutMePage } from "./AboutMePage";
import { GalleryPaintingPage } from "./galleryPaintingPage/GalleryPaintingPage";
import { GalleryDrawingPage } from "./galleryDrawingPage/GalleryDrawingPage";
import { ContactPage } from "./ContactPage";
import { GalleryPage } from "./galleryPage/GalleryPage";
import { ScrollToTop } from "../common/utils/scrollToUp/ScrollToUp";
import { AdminPanelPage } from "./adminPanelPage/AdminPanelPage";

export const Routing = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={HOME_PAGE} Component={HomePage} />
        <Route path={ABOUT_ME_PAGE} Component={AboutMePage} />
        <Route path={GALLERY_PAGE} Component={GalleryPage} />
        <Route path={GALLERY_PAINTINGS_PAGE} Component={GalleryPaintingPage} />
        <Route path={GALLERY_DRAWINGS_PAGE} Component={GalleryDrawingPage} />
        <Route path={CONTACT_PAGE} Component={ContactPage} />
        <Route path={ADMIN_PANEL_PAGE} Component={AdminPanelPage} />
      </Routes>
    </BrowserRouter>
  );
};
