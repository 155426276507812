import React, { useState, FormEvent } from "react";
import { useAuth } from "../../modules/auth/AuthContext";
import { ADMIN_LOGIN, ADMIN_PASSWORD } from "../../common/utils/env";
import { Box, FlexBox } from "../../common/components/Box";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {
  GALLERY_DRAWINGS_PAGE,
  GALLERY_PAINTINGS_PAGE,
  HOME_PAGE,
} from "../paths";

export const AdminPanelPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();
    if (username === ADMIN_LOGIN && password === ADMIN_PASSWORD) {
      auth?.handleLogin({ username });
      setUsername("");
      setPassword("");
    } else {
      alert("Niepoprawne dane logowania");
    }
  };

  if (!auth?.user) {
    return (
      <Box px="16px">
        <h1>Logowanie do Panelu Administracyjnego</h1>
        <form onSubmit={handleLogin}>
          <FlexBox gridGap="10px" flexDirection="column">
            <Box>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Box>
            <Box>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Box>
              <Button variant="contained" type="submit">
                Zaloguj
              </Button>
            </Box>
          </FlexBox>
        </form>
      </Box>
    );
  }

  return (
    <Box px="16px">
      <h1>Panel Administracyjny</h1>
      <Box>Jestes zalogowany</Box>
      <Box mt="16">nawigacja po stronie:</Box>

      <FlexBox p="10px" gridGap="10px" flexDirection="column">
        <Box>
          <Button variant="outlined" onClick={() => navigate(HOME_PAGE)}>
            Strona główna
          </Button>
        </Box>
        <Box>
          <Button
            variant="outlined"
            onClick={() => navigate(GALLERY_DRAWINGS_PAGE)}
          >
            Rysunki
          </Button>
        </Box>
        <Box>
          <Button
            variant="outlined"
            onClick={() => navigate(GALLERY_PAINTINGS_PAGE)}
          >
            Obrazy
          </Button>
        </Box>
      </FlexBox>

      <Box mt="30px">
        <Button variant="outlined" onClick={() => auth?.handleLogout()}>
          Wyloguj się
        </Button>
      </Box>
    </Box>
  );
};
